<template>
  <div class="login">
    <div class="login-box">
      <van-image
        fit="contain"
        src="http://i.cdn.quyixian.com/printManage/top-bg.png"
      />
      <h1 class="login-title">欢迎登录</h1>
      <van-form class="login-form" @submit="onSubmit">
        <van-field
          v-model="username"
          type="number"
          left-icon="http://i.cdn.quyixian.com/printManage/user.png"
          placeholder="请输入用户名"
        />
        <van-field
          v-model="password"
          type="password"
          left-icon="http://i.cdn.quyixian.com/printManage/pwd.png"
          placeholder="请输入密码"
        />
        <van-button round block type="info" native-type="submit"
          >确认登录</van-button
        >
      </van-form>
    </div>
    <div class="tel">如遇问题，请联系010-87605362</div>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
export default {
  name: "login",
  data() {
    return {
      username: "", //李福胜
      password: "", //123123
    };
  },
  mounted() {
    let user = JSON.parse(this.tool.getCookie("user"));
    if (user != null) {
      this.$router.replace({
        name: "home",
      });
    }
  },
  methods: {
    onSubmit() {
      Toast.loading({
        message: "登陆中...",
        forbidClick: true,
        loadingType: "spinner",
      });
      let that = this;
      this.$api.print
        .login({ userName: this.username, password: this.password })
        .then((res) => {
          let { tag, data, message } = res;
          if (tag == 1) {
            this.$cookies.set("user", data, "30d");
            //this.tool.setCookie("user", JSON.stringify(data));
            Toast({
              message: "登录成功",
              icon: "success",
            });
            setTimeout(() => {
              that.$router.replace({
                name: "home",
              });
            }, 3000);
          } else {
            Dialog.alert({
              title: "登录失败",
              message: message,
              confirmButtonText: "刷新",
            }).then(() => {
              //this.tool.clearCookie("user");
              this.$cookies.remove("user");
              this.$router.replace({
                name: "login",
              });
            });
          }
        });
    },
  },
};
</script>

<style lang="scss">
.login {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  &-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
  }
  &-title {
    font-size: 42px;
    color: #333;
    font-weight: 500;
    text-align: left;
    margin: 0 60px;
  }
  &-form {
    margin: 0 60px;

    .van-cell {
      padding: 40px 0;
      font-size: 28px;
    }
    .van-field__left-icon {
      margin-right: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .van-button--info {
      margin-top: 60px;
      background: linear-gradient(to right, #3f8fff, #0750ff);
    }
  }
  .tel {
    color: #999;
    font-size: 24px;
    text-align: center;
    margin-bottom: 30px;
  }
}
</style>
