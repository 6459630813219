let baseUrl = "https://yz.api.53online.cn/api/";
//let baseUrl = "http://210.13.51.100:8015/api/";
export default {
  //登录
  loginUrl: baseUrl + "Login/Login",

  //材料出入库-扫ID 获取信息
  getOutinUrl: baseUrl + "MaterialsBuyOrderOutIn/GetOutIn",
  //材料出入库-保存信息
  saveOutinUrl: baseUrl + "MaterialsBuyOrderOutIn/SaveOutIn",
  //材料出入库-上传凭证
  uploadfileOutinUrl:
    baseUrl + "MaterialsBuyOrderOutIn/UploadFileYwtMaterialsBuyOrderOutIn",

  //获取印厂工序统计列表     主列表
  flowmainUrl: baseUrl + "Flow/GetWorkingProcedureList",
  //获取印厂付印单号列表明细  次列表
  flowmainlistUrl: baseUrl + "Flow/GetFydList",
  ///获取印厂付印单号列表明细 搜索用
  flowlistUrl: baseUrl + "Flow/GetFydNoList",
  //获取印厂付印单号列表明细  进度
  progressUrl: baseUrl + "Flow/GetFlowDetailList",
  //prpgressUrl  更新进度
  updateprogressUrl: baseUrl + "Flow/SaveUpdateFlowComletionRate",

  //回单分页列表
  deductlistUrl: baseUrl + "DeductMoney/GetPageList",
  //回单保存
  deductsaveUrl: baseUrl + "DeductMoney/Save",
  //回单上传图
  deductUploadUrl: baseUrl + "DeductMoney/UploadFileOSSPressDeductMoney",
};
