<template>
  <div class="print">
    <div class="print-head" v-if="user.pressName">
      <div class="print-head-title">
        <div @click="exit" class="print-head-nav none">
          <van-icon name="exchange" />退出
        </div>
        <p>{{ user.pressName }}</p>
        <span class="none"></span>
      </div>
      <div class="print-head-info">
        <router-link :to="{ name: 'deduct' }" class="btn">单据回传</router-link>
        <a class="btn" href="/materials">材料出入库</a>
        <!-- <router-link :to="{ name: 'materials' }" class="btn"
          >材料出入库</router-link
        > -->
      </div>
    </div>
    <div class="print-homesearch">
      <div class="print-homesearch-box"></div>
      <div class="print-homesearch-list">
        <van-search
          v-model="key"
          placeholder="订单号搜索"
          show-action
          @search="onSearch"
          @cancel="onCancel"
        />
        <template v-if="searchList.length > 0">
          <div class="list" v-for="(s, i) in searchList" :key="i">
            <router-link
              class="number"
              :to="{ name: 'details', query: { fydid: s.fydNo } }"
              >{{ s.fydNo }}</router-link
            >
          </div>
        </template>
      </div>
    </div>
    <template v-if="loading">
      <template v-if="list.length > 0">
        <div
          class="print-mlist"
          v-for="(item, index) in list"
          :key="index"
          @click="clickItem(index)"
        >
          <van-image class="print-mlist-img" fil="contain" :src="item.icon" />
          <div class="print-mlist-main">
            <p class="title">{{ item.workingProcedure }}</p>
            <div class="info">
              <span>订单{{ item.fydNoCount }}个</span><em>|</em
              ><span>印制项目{{ item.workingProcedureCount }}个</span><em>|</em
              ><span>剩余工量{{ item.num }}{{ item.unit }}</span>
            </div>
          </div>
          <van-icon class="print-mlist-icon" name="arrow" />
        </div>
      </template>
      <template v-else>
        <div class="print-nolist">
          <van-empty description="暂无数据" />
        </div>
      </template>
    </template>
    <template v-else>
      <div class="print-loading">
        <van-loading type="spinner" color="#0094ff" vertical
          >数据加载中...</van-loading
        >
      </div>
    </template>
  </div>
</template>
<script>
import { Dialog, Toast } from "vant";
export default {
  name: "Home",
  data() {
    return {
      loading: false,
      user: "",
      token: "",
      list: [],
      key: "",
      searchList: [],
    };
  },
  mounted() {
    if (this.$cookies.isKey("user")) {
      this.user = this.$cookies.get("user");
      //console.log(this.user);
      this.token = { Authorization: "Bearer " + this.user.token };
      this.getMainList();
    } else {
      this.$router.replace({
        name: "login",
      });
    }
  },
  methods: {
    exit() {
      Dialog.confirm({
        title: "提示",
        message: "确定退出登录？",
      })
        .then(() => {
          this.$cookies.remove("user");
          setTimeout(() => {
            this.$router.replace({
              name: "login",
            });
          }, 1000);
        })
        .catch(() => {
          //console.log("cancel");
        });
    },
    onSearch(val) {
      this.$api.print.searchList({ fydNo: val }, this.token).then((res) => {
        let { data, tag } = res;
        if (tag == 1) {
          if (data.length > 0) {
            this.searchList = data;
            console.log(this.searchList);
          } else {
            Toast.fail("未搜索到数据");
            //无数据
          }
        } else {
          Toast.fail("搜索出错");
        }
      });
    },
    onCancel() {
      this.searchList = [];
    },
    getMainList() {
      this.$api.print.mainList({}, this.token).then((res) => {
        let { data, tag, message } = res;
        if (tag == 1) {
          if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              if (data[i].workingProcedure.search(/印刷/) > -1) {
                data[i].icon =
                  "http://i.cdn.quyixian.com/printManage/%E5%8D%B0%E5%88%B7.png";
              } else if (data[i].workingProcedure.search(/胶订/) > -1) {
                data[i].icon =
                  "http://i.cdn.quyixian.com/printManage/%E8%83%B6%E8%AE%A2.png";
              } else if (data[i].workingProcedure.search(/刷胶/) > -1) {
                data[i].icon =
                  "http://i.cdn.quyixian.com/printManage/%E5%88%B7%E8%83%B6.png";
              } else if (data[i].workingProcedure.search(/骑订/) > -1) {
                data[i].icon =
                  "http://i.cdn.quyixian.com/printManage/%E9%AA%91%E8%AE%A2.png";
              } else {
                data[i].icon =
                  "http://i.cdn.quyixian.com/printManage/%E5%A4%84%E7%90%86.png";
              }
            }
            this.list = data;
          }
          this.loading = true;
        } else {
          Toast("获取数据失败");
        }
      });
    },
    clickItem(index) {
      let params = {
        // pressId: this.user.pressId,
        // pressName: this.user.pressName,
        // userId: this.user.userId,
        // userName: this.userName,
        workingProcedure: this.list[index].workingProcedure,
        workingProcedureCount: this.list[index].workingProcedureCount,
        fydNoCount: this.list[index].fydNoCount,
        num: this.list[index].num,
        unit: this.list[index].unit,
      };
      sessionStorage.setItem(
        this.list[index].workingProcedure,
        JSON.stringify(params)
      );

      Toast.loading({
        message: "加载中...",
      });
      let that = this;
      setTimeout(() => {
        that.$router.push({
          name: "details",
          query: { wp: this.list[index].workingProcedure },
        });
      }, 1000);
    },
  },
};
</script>
