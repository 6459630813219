<template>
  <div class="print">
    <div class="print-head" v-if="user.pressName">
      <div class="print-head-title">
        <router-link :to="{ name: 'home' }" class="print-head-nav none">
          <van-icon name="wap-home-o" />回首页
        </router-link>
        <p>{{ user.pressName }}</p>
        <span class="none"></span>
      </div>
      <div class="print-head-info">
        <router-link :to="{ name: 'deductadd' }" class="btn"
          >添加单据</router-link
        >
      </div>
    </div>
    <div class="print-search">
      <van-field
        left-icon="search"
        v-model="searchName"
        placeholder="类型搜索"
        readonly
        @click="searchShowPopup = true"
      />
      <van-popup
        v-model="searchShowPopup"
        position="bottom"
        :style="{ height: '50%' }"
      >
        <van-picker
          title="单据类型"
          show-toolbar
          :columns="typeList"
          default-index="2"
          @confirm="searchBtn"
          @cancel="searchShowPopup = false"
        />
      </van-popup>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedtext"
        @load="onLoad"
      >
        <template v-if="list.length > 0">
          <div class="print-mitem" v-for="(item, index) in list" :key="index">
            <div class="print-mitem-title">
              <span>{{ item.title }}</span>
            </div>
            <div class="print-mitem-content">
              <span>{{ item.typeTitle }}</span>
              <span>{{ item.addTime }}</span>
            </div>
            <van-button
              class="print-mitem-btn"
              round
              block
              @click="clickDown(item.imgUrl)"
              >查看扣款单</van-button
            >
          </div>
        </template>
        <template v-else>
          <div class="print-nolist">
            <van-empty description="暂无数据" />
          </div>
        </template>
      </van-list>
    </van-pull-refresh>

    <!-- <template v-else>
      <div class="print-nolist">
        <van-empty description="暂无数据" />
      </div>
    </template> -->
  </div>
</template>

<script>
import { Dialog, Toast, ImagePreview } from "vant";
export default {
  name: "deduct",
  data() {
    return {
      user: "",
      token: "",
      typeList: [
        "无类型",
        "亏数图书扣款单",
        "读者投诉罚款单",
        "入库质量罚款单",
        "质量事故罚款单",
        "采集异常罚款单",
        "原辅料入库单",
        "油墨对账单",
        "热熔胶对账单",
        "印装费对账单",
        "纸张对账单",
      ],
      loading: false,
      finished: false,
      finishedtext: "没有更多了",
      refreshing: false,
      list: [],
      searchShowPopup: false,
      search: "",
      searchName: "",
      pageCurr: 0, //当前页数
    };
  },
  mounted() {
    if (this.$cookies.isKey("user")) {
      this.user = this.$cookies.get("user");
      //console.log(this.user);
      this.token = { Authorization: "Bearer " + this.user.token };
    } else {
      this.$router.replace({
        name: "login",
      });
    }
  },
  methods: {
    onLoad() {
      //console.log('被触发了')

      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.pageCurr = 0;
          this.search = "";
          this.searchName = "";
          this.refreshing = false;
          //console.log('触发顶部')
        }
        this.pageCurr += 1;
        let params = { pageindex: this.pageCurr, type: this.search };
        this.$api.print.deductList(params, this.token).then((res) => {
          let { total, data, tag } = res;
          if (tag == 1) {
            if (data.length <= 0) {
              this.finishedtext = "";
            } else {
              this.list = this.list.concat(data);
            }
            this.loading = false;
            if (this.list.length >= total) {
              this.finished = true;
              //console.log('底部没有可继续的了')
            }
          } else {
            //数据异常
            Toast.fail("获取数据失败");
          }
          //console.log(this.list)
        });
      }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    getdeductList(currPage, key) {
      this.$api.print
        .deductList({ pageindex: currPage, type: key }, this.token)
        .then((res) => {
          let { total, data, tag } = res;
          if (tag == 1) {
            this.pageCount = total;
            this.list = data;
          } else {
            //数据异常
          }
          console.log(res);
        });
    },
    searchBtn(value, key) {
      this.searchShowPopup = false;
      if (value == "无类型") {
        this.searchName = "";
        this.search = "";
      } else {
        this.searchName = value;
        this.search = key;
      }
      this.list = [];
      this.pageCurr = 0;
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    clickDown(url) {
      ImagePreview({ images: [url], closeable: true, showIndex: false });
    },
  },
};
</script>

<style></style>
