<template>
  <div class="print">
    <div class="print-head" v-if="user.pressName">
      <div class="print-head-title">
        <router-link :to="{ name: 'home' }" class="print-head-nav none">
          <van-icon name="wap-home-o" />回首页
        </router-link>
        <p>{{ user.pressName }}</p>
        <span class="none"></span>
      </div>
      <div class="print-head-info" v-if="work">
        <div class="title">{{ work.workingProcedure }}</div>
        <div class="details">
          <span>订单{{ work.fydNoCount }}个</span><em>|</em
          ><span>印制项目{{ work.workingProcedureCount }}个</span><em>|</em
          ><span>剩余工量{{ work.num }}{{ work.unit }}</span>
        </div>
      </div>
    </div>

    <template v-if="loading">
      <template v-if="detailsList.length > 0">
        <div
          class="print-mitem"
          v-for="(item, index) in detailsList"
          :key="index"
        >
          <div class="print-mitem-title">
            <span>{{ item.fydNo }}</span>
            <span>{{ item.fydTypeName }}</span>
          </div>
          <div class="print-mitem-content">{{ item.bookName }}</div>
          <van-button
            class="print-mitem-btn"
            round
            block
            @click="clickProgress(item.fydNo, item.fydTypeName)"
            >进度管理</van-button
          >
        </div>
      </template>
      <template v-else>
        <div class="print-nolist">
          <van-empty description="暂无数据" />
        </div>
      </template>
    </template>
    <template v-else>
      <div class="print-loading">
        <van-loading type="spinner" color="#0094ff" vertical
          >数据加载中...</van-loading
        >
      </div>
    </template>
  </div>
</template>

<script>
import { Dialog, Toast, Icon } from "vant";
export default {
  name: "det",
  data() {
    return {
      loading: false,
      wp: this.$route.query.wp,
      fydid: this.$route.query.fydid,
      work: "",
      detailsList: [],
      user: "",
      token: "",
    };
  },
  mounted() {
    if (this.$cookies.isKey("user")) {
      this.user = this.$cookies.get("user");
      //console.log(this.user);
      this.token = { Authorization: "Bearer " + this.user.token };
      if (this.wp) {
        this.work = JSON.parse(sessionStorage.getItem(this.wp));
      }
      this.getDetailsList();
    } else {
      this.$router.replace({
        name: "login",
      });
    }
  },
  methods: {
    getDetailsList() {
      let params = {
        workingProcedure: this.wp,
      };
      if (this.fydid) {
        params = {
          fydNo: this.fydid,
        };
      }
      this.$api.print.detailsList(params, this.token).then((res) => {
        console.log(res);
        let { tag, data } = res;
        if (tag == 1) {
          this.loading = true;
          this.detailsList = data;
        } else {
          Toast("获取数据失败");
        }
      });
    },
    clickProgress(id, name) {
      // this.work.fydid = id
      // this.work.fydname = name
      // sessionStorage.setItem(this.work.workingProcedure, JSON.stringify(this.work))
      Toast.loading({
        message: "加载中...",
      });
      let that = this;
      let query = {
        id: id,
        name: name,
        wp: this.work == null ? "" : this.work.workingProcedure,
      };
      setTimeout(() => {
        that.$router.push({ name: "progress", query: query });
      }, 1000);
      //console.log(this.work)
    },
  },
};
</script>

<style></style>
