<template>
  <div class="mater">
    <template v-if="qrcodeShow">
      <van-button
        class="qrcode"
        type="info"
        @click="clickQrcode"
        :disabled="qrcodeBtnState"
        ><van-icon name="scan" />{{ qrcodeBtnText }}</van-button
      >
    </template>
    <template v-else>
      <div class="mater-info">
        <div class="mater-info-mbox">
          <router-link :to="{ name: 'home' }" class="mater-info-nav none">
            <van-icon name="wap-home-o" />回首页
          </router-link>
          <div class="mater-info-title">
            <img src="https://i.cdn.quyixian.com/printManage/logo.png" alt="" />
            <p>曲一线材料出/入库</p>
          </div>
          <span class="none"></span>
        </div>
        <div class="mater-info-box" v-if="model.supplierName">
          <span>供料单位：</span>
          <p>{{ model.supplierName }}</p>
        </div>
        <div class="mater-info-box" v-if="model.pressName">
          <span>存料单位：</span>
          <p>{{ model.pressName }}</p>
        </div>
        <div class="mater-info-box" v-if="model.outInNo">
          <span>单据编号：</span>
          <p>{{ model.outInNo }}</p>
        </div>
        <div class="mater-info-box" v-if="model.outInType">
          <span>入库类型：</span>
          <p>{{ model.outInType }}</p>
        </div>
        <div class="mater-info-box" v-if="model.estimateTime">
          <span>计划到货时间：</span>
          <p>{{ model.estimateTime }}</p>
        </div>
        <div class="mater-info-box" v-if="model.batchNumber">
          <span>生产批次：</span>
          <p>{{ model.batchNumber }}</p>
        </div>
        <div class="mater-info-box" v-if="model.materialsNo">
          <span>材料编号：</span>
          <p>{{ model.materialsNo }}</p>
        </div>
        <div class="mater-info-box" v-if="model.classesIdTitle">
          <span>材料细分：</span>
          <p>{{ model.classesIdTitle }}</p>
        </div>
        <div class="mater-info-box" v-if="model.gram">
          <span>克重：</span>
          <p>{{ model.gram }}</p>
        </div>
        <div class="mater-info-box" v-if="model.width">
          <span>宽：</span>
          <p>{{ model.width }}</p>
        </div>
        <div class="mater-info-box" v-if="model.length">
          <span>长：</span>
          <p>{{ model.length }}</p>
        </div>
        <div class="mater-info-box" v-if="model.height">
          <span>高：</span>
          <p>{{ model.height }}</p>
        </div>
        <div class="mater-info-box" v-if="model.title">
          <span>材料名称：</span>
          <p>{{ model.title }}</p>
        </div>
        <div class="mater-info-box" v-if="model.units">
          <span>单位：</span>
          <p>{{ model.units }}</p>
        </div>
        <div class="mater-info-box" v-if="model.planInNum">
          <span>计划数量：</span>
          <p>{{ model.planInNum }}</p>
        </div>
        <div class="mater-info-box" v-if="model.baseCreatorName">
          <span>发起人：</span>
          <p>{{ model.baseCreatorName }}</p>
        </div>
      </div>
      <van-form class="mater-form" @submit="onSubmit">
        <van-field
          class="formNum"
          v-model="num"
          type="number"
          label="实际出入库数量："
          placeholder="请填写实际出入库数量"
        />
        <van-field
          v-model="receivingTime"
          name="receivingTime"
          label="单据签收日期："
          required
          placeholder="单据签收日期"
          :rules="[{ required: true }]"
          @click="receivingTimeShow = true"
          @focus="noBomBox"
          readOnly
        />
        <van-popup v-model="receivingTimeShow" position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="date"
            title="选择年月日"
            :min-date="minDate"
            confirm-button-text="确定"
            cancel-button-text="关闭"
            :max-date="maxDate"
            @cancel="receivingTimeShow = false"
            @confirm="receivingTimeConfirm"
          />
        </van-popup>
        <van-field
          v-model="itemNumber"
          type="number"
          label="件数/卷数："
          placeholder="请输入具体件/卷数"
        />
        <van-field
          v-model="quality"
          rows="2"
          autosize
          label="出入库质量："
          placeholder="请简单说明出入库质量，有扣残在这里描述"
          type="textarea"
          maxlength="100"
          show-word-limit
        />
        <div style="color: #fff">默认：合格，如有扣残请在出入库质量中描述</div>
        <div class="mater-form-upload">
          <span>上传凭证：</span>
          <van-uploader
            v-model="image"
            max-count="3"
            :after-read="afterRead"
            :before-read="beforeRead"
            accept="image/*"
          />
        </div>
        <van-button block type="info" :disabled="subState" native-type="submit"
          >保存实际出入库</van-button
        >
      </van-form>
    </template>
  </div>
</template>

<script>
import fileUtils from "../assets/utils";
import ImageCompressor from "../assets/ImageCompressor";
import { Dialog, Toast, Notify } from "vant";
import qs from "qs";

export default {
  name: "materials",
  data() {
    return {
      loading: false,
      user: "",
      token: "",
      qrcodeId: this.$route.query.qid == undefined ? "" : this.$route.query.qid,
      qrcodeShow: true, //是否显示扫码按钮
      qrcodeBtnState: false, //扫一扫按钮状态且换
      qrcodeBtnText: "",
      model: "",
      num: "", //入库数量
      image: [], //凭证图
      itemNumber: "", //件数/卷数
      quality: "合格", //入库质量
      subState: false, //表单提交按钮状态且换
      receivingTime: "", //单据签收日期
      receivingTimeShow: false,
      currentDate: new Date(),
      maxDate: new Date(2030, 11, 31),
      minDate: new Date(1950, 0, 1),
    };
  },
  watch: {
    qrcodeBtnState: {
      handler(n, o) {
        if (n == true) {
          this.qrcodeBtnText = "正在打开微信扫一扫";
        } else {
          this.qrcodeBtnText = "微信扫单据二维码";
        }
      },
      immediate: true,
    },
    image: function (n, o) {
      for (let j = 0; j < n.length; j++) {
        if (!this.image[j].url) {
          this.image.splice(j, 1);
        }
      }
    },
  },
  mounted() {
    if (this.$cookies.isKey("user")) {
      this.user = this.$cookies.get("user");
      //console.log(this.user);
      this.token = { Authorization: "Bearer " + this.user.token };

      //扫过码
      if (this.qrcodeId != "") {
        this.qrcodeShow = false;
        this.model = qs.parse(sessionStorage.getItem(this.qrcodeId));
        // if (this.model.planInNum && this.model.planInNum > 0) {
        //   this.num = this.model.planInNum;
        // }
      }
      // else {
      //   this.$api.print
      //     .getOutIn({ id: "640544869126770688" }, this.token)
      //     .then((res) => {
      //       let { tag, data, message } = res;
      //       if (tag == 1) {
      //         //切换状态 扫码成功到数据
      //         sessionStorage.setItem(data.id, qs.stringify(data));
      //         window.location.replace(window.location.href + "?qid=" + data.id);
      //       } else {
      //         //扫码后查询数据出错
      //         Dialog.alert({
      //           title: "查询发生错误",
      //           message: message,
      //         }).then(() => {
      //           this.qrcodeBtnState = false;
      //         });
      //       }
      //     });
      // }
    } else {
      this.$router.replace({
        name: "login",
      });
    }
  },
  methods: {
    noBomBox(Event) {
      document.activeElement.blur();
    },
    clickQrcode() {
      if (this.qrcodeBtnState == false) {
        this.qrcodeBtnState = true;
        this.$wechat.scanQRCode().then((res) => {
          let { resultStr, errMsg } = res;
          //alert(res);
          if (errMsg == "scanQRCode:ok") {
            if (resultStr) {
              //访问相关接口拿数据
              this.$api.print
                .getOutIn({ id: resultStr }, this.token)
                .then((res) => {
                  let { tag, data, message } = res;
                  if (tag == 1) {
                    //扫码成功-缓存数据 跳转页面
                    sessionStorage.setItem(data.id, qs.stringify(data));
                    window.location.replace(
                      window.location.href + "?qid=" + data.id
                    );
                    // this.model = data;
                  } else {
                    //扫码后查询数据出错
                    Dialog.alert({
                      title: "查询发生错误",
                      message: message,
                    }).then(() => {
                      this.qrcodeBtnState = false;
                    });
                  }
                });
            }
          } else {
            this.qrcodeBtnState = false;
            Notify("打开扫一扫失败");
          }
        });
      }
    },
    beforeRead(file) {
      return new Promise((resolve, reject) => {
        fileUtils.getOrientation(file).then((orient) => {
          if (orient && orient === 6 && fileUtils.ifHighThanIos13()) {
            let reader = new FileReader();
            let img = new Image();
            reader.onload = (e) => {
              img.src = e.target.result;
              img.onload = function () {
                const data = fileUtils.rotateImage(img, img.width, img.height);
                const newFile = fileUtils.dataURLtoFile(data, file.name);
                resolve(newFile);
              };
            };
            reader.readAsDataURL(file);
          } else {
            resolve(file);
          }
        });
      });
    },
    afterRead(file) {
      this.handleUpload(file, 500, 0.6);
    },
    receivingTimeConfirm(e) {
      this.receivingTime = this.timeFormat(new Date(e));
      this.receivingTimeShow = false;
    },
    timeFormat(time) {
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      return year + "年" + month + "月" + day + "日";
    },
    onSubmit() {
      this.subState = true;
      if (this.image.length <= 0) {
        Notify("请上传凭证");
        this.subState = false;
        return false;
      }
      if (this.num == "") {
        Notify("请填写实际出入库数量");
        this.subState = false;
        return false;
      }
      if (this.quality == "") {
        Notify("请简单说明出入库质量");
        this.subState = false;
        return false;
      }
      //单据签收日期
      if (this.receivingTime == "") {
        Notify("请选择单据签收日期");
        this.subState = false;
        return false;
      }

      //接口保存
      let img = "";
      if (this.image.length > 0) {
        for (let i = 0; i < this.image.length; i++) {
          img += this.image[i].url + ",";
        }
        img = img.slice(0, img.length - 1);
      }
      let params = {
        id: this.model.id,
        outTime: this.formatDate(),
        practicalInNum: this.num,
        itemNumber: this.itemNumber,
        quality: this.quality,
        receivingTime: this.receivingTime,
        imgUrl: img,
      };
      this.$api.print.saveOutIn(params, this.token).then((res) => {
        let { tag, data, message } = res;
        console.log(res);
        if (tag == 1) {
          //成功跳转页面
          Dialog.confirm({
            title: "保存成功",
            message: "回到首页或回到扫码",
            confirmButtonText: "继续扫码",
            cancelButtonText: "回到首页",
          })
            .then(() => {
              //继续扫码
              this.qrcodeShow = true;
              this.model = "";
              this.qrcodeBtnState = false;
              this.subState = false;
            })
            .catch(() => {
              //回到首页
              this.$router.replace({ name: "home" });
            });
        } else {
          Dialog.alert({
            title: "保存发生错误",
            message: message,
          }).then(() => {
            this.subState = false;
          });
        }
      });
    },

    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      // let week = date.getDay(); // 星期
      // let weekArr = [
      //   "星期日",
      //   "星期一",
      //   "星期二",
      //   "星期三",
      //   "星期四",
      //   "星期五",
      //   "星期六",
      // ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      //`${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
      let t = `${year}/${month}/${day} ${hour}:${minute}:${second} `;
      return t;
    },

    /*----------------*/
    /**
     * base64 转 Blob 格式 和file格式
     */
    base64UrlToBlob(urlData) {
      let arr = urlData.split(","),
        mime = arr[0].match(/:(.*?);/)[1], // 去掉url的头，并转化为byte
        bstr = atob(arr[1]), // 处理异常,将ascii码小于0的转换为大于0
        n = bstr.length,
        u8arr = new Uint8Array(n);
      //console.log("arr:" + arr);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      // 转blob
      // return new Blob([u8arr], {type: mime})
      let filename = Date.parse(new Date()) + ".jpg";
      // 转file
      return new File([u8arr], filename, { type: mime });
    },
    /*
      压缩图片
      file：文件(类型是图片格式)，
      obj：文件压缩后对象width， height， quality(0-1)
      callback：容器或者回调函数
    */
    photoCompress(file, obj, callback) {
      let that = this;
      let ready = new FileReader();
      /* 开始读取指定File对象中的内容. 读取操作完成时,返回一个URL格式的字符串. */
      ready.readAsDataURL(file);
      ready.onload = function () {
        let re = this.result;
        console.log(re);
        that.canvasDataURL(re, obj, callback); // 开始压缩
      };
    },
    /* 利用canvas数据化图片进行压缩 */
    /* 图片转base64 */
    canvasDataURL(path, obj, callback) {
      let img = new Image();
      img.src = path;
      img.onload = function () {
        let that = this; // 指到img
        // 默认按比例压缩
        let w = that.width,
          h = that.height,
          scale = w / h;
        w = obj.width || w;
        h = obj.height || w / scale;
        let quality = 0.7; // 默认图片质量为0.7
        // 生成canvas
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        // 创建属性节点
        let anw = document.createAttribute("width");
        anw.nodeValue = w;
        let anh = document.createAttribute("height");
        anh.nodeValue = h;
        canvas.setAttributeNode(anw);
        canvas.setAttributeNode(anh);
        ctx.drawImage(that, 0, 0, w, h);
        // 图像质量
        if (obj.quality) {
          quality = obj.quality;
          //console.log("quality:" + quality);
        }
        // quality值越小，所绘制出的图像越模糊
        let base64 = canvas.toDataURL("image/jpeg", quality);
        // 回调函数返回base64的值
        callback(base64);
      };
    },
    //  返回file文件，调用接口执行上传
    async uploadLice(files) {
      const formData = new FormData();
      formData.append("FileData", files);
      return await this.$api.print.uploadFileOutIn(formData, this.token);
    },
    //图片上传 1.file(原文件) 2.size(判断大小-kb) 3.quality(质量)
    handleUpload(file, size, quality) {
      file.status = "uploading";
      file.message = "上传中...";
      let that = this;
      let files = file.file;
      if (files === undefined) {
        return;
      }
      //KB 判断是否压缩图
      if (files.size / 1024 > size) {
        // 文件大于100K（根据需求更改），进行压缩上传
        this.photoCompress(files, { quality: quality }, function (base64Codes) {
          // console.log("压缩后：" + base.length / 1024 + " " + base);
          let bl = that.base64UrlToBlob(base64Codes);
          // file.append('file', bl, 'file_' + Date.parse(new Date()) + '.jpg') // 文件对象
          // 请求图片上传接口
          that.uploadLice(bl).then((res) => {
            file.status = "done";
            if (res && res.data) {
              that.image.push({ url: res.data });
            } else {
              file.status = "failed";
              file.message = "上传失败";
              return;
            }
          });
        });
      } else {
        // 判断大小-直接上传原图
        this.uploadLice(files).then((res) => {
          file.status = "done";
          if (res && res.data) {
            that.image.push({ url: res.data });
          } else {
            file.status = "failed";
            file.message = "上传失败";
            return;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.mater {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px;

  .qrcode {
    margin-top: 100px;
    background: linear-gradient(to right, #3f8fff, #0750ff);

    .van-button__text {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .van-icon-scan {
      margin-right: 10px;
      font-size: 28px;
    }
  }

  &-info {
    font-size: 24px;
    color: #666;

    &-mbox {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-nav {
      width: 140px;
      font-size: 20px;
      color: #333;
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      .van-icon {
        font-size: 48px !important;
      }
    }
    .none {
      width: 140px;
    }

    &-title {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 36px;
      font-weight: normal;
      text-align: center;
    }

    &-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 5px 0;

      &:not(&:nth-child(1)) {
        border-bottom: 1px solid #cecece;
      }

      span {
        width: 200px;
        text-align: right;
      }
    }
  }
  &-form {
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
    input::placeholder,
    textarea::placeholder {
      color: #fff;
    }

    .van-cell {
      background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
      padding: 15px 0;
      color: #333 !important;
      &::after {
        border-bottom: 1px solid #cecece;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    .van-field__label {
      width: 8em;
      color: #333 !important;
      text-align: right;
    }

    &-upload {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 15px 0;
      border-bottom: 1px solid #cecece;

      span {
        width: 8.5em;
        text-align: right;
      }
      .van-uploader {
        margin-left: 20px;

        .van-uploader__preview {
          margin: 0 6px 6px 0;
        }
      }
    }
    .van-button {
      margin: 30px 0;
      background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
      //background: linear-gradient(to right, #ffbd6e, #ffad4a);
      border: 0;
    }
  }
}
</style>
