import api from "../index";
import urls from "./url";

export default {
  //登录
  login(params) {
    return api.post(urls.loginUrl, params);
  },
  //主列表
  mainList(params, head) {
    return api.post(urls.flowmainUrl, params, head);
  },
  //详情列表
  detailsList(params, head) {
    return api.post(urls.flowmainlistUrl, params, head);
  },
  //搜索列表
  searchList(params, head) {
    return api.post(urls.flowlistUrl, params, head);
  },
  //进度列表
  progressList(params, head) {
    return api.post(urls.progressUrl, params, head);
  },
  //更新进度
  UpdateProgress(params, head) {
    return api.post(urls.updateprogressUrl, params, head);
  },

  //回单分页列表
  deductList(params, head) {
    return api.post(urls.deductlistUrl, params, head);
  },
  //回单保存
  deductSave(params, head) {
    return api.post(urls.deductsaveUrl, params, head);
  },
  //回单上传图
  deductUpload(params, head) {
    return api.post(urls.deductUploadUrl, params, head);
  },

  //材料出入库-获取信息
  getOutIn(params, head) {
    return api.post(urls.getOutinUrl, params, head);
  },
  //材料出入库-保存信息
  saveOutIn(params, head) {
    return api.post(urls.saveOutinUrl, params, head);
  },
  //材料出入库-上传凭证
  uploadFileOutIn(params, head) {
    return api.post(urls.uploadfileOutinUrl, params, head);
  },
};
